import React, { useState } from 'react'

function Select({setSelectCount}) {
    const [count, setCount] = useState(0);
    function selectCount(e){
        setCount(e.target.value);
        setSelectCount(e.target.value);
    }
    return (
        <div className="select">
            <h3 className="select-title">Raqamni kiriting(1-250 oralig'ida har bir raqamda 20 ta so'z bor)</h3>
            <input className="select-number" type="number" value={count} onChange={selectCount} />
        </div>
    )
}

export default Select
