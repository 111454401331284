import React from 'react'

function TableDictionary({dictionary, selectCount, selectValue}) {
    console.log(selectCount);
    return (
        <div className="table-dictionary">
            <table>
                <thead>
                    <tr>
                        <th>N</th>
                        <th>Word / Phrase</th>
                        <th>Phonetic Spelling</th>
                        <th>Translation</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        dictionary.filter(item => item.idMain === parseInt(selectCount))
                        .map((item)=>(
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.english}</td>
                                <td>{item.transciption}</td>
                                <td>{item.uzbek}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default TableDictionary
