import React, { useEffect } from "react";
import { useState } from "react/cjs/react.development";
import { DataDictionary } from "../Data";

function TestDictionary({ selectCount }) {
    const { rando, randoSequence } = require("@nastyox/rando.js");
    const [words, setWords] = useState([]);
    const [count, setCount] = useState(1);
    const [score, setScore] = useState(0);
    const [isScore, setIsScore] = useState(true);
    useEffect(() => {
        setWords(DataDictionary);
    }, []);

    function reset(){
        setIsScore(true);
        setScore(0);
        setCount(1);
    }

    function onClickNext(index) {
        console.log(count);
        if (index === 0) {
            setCount((prev) => prev + 1);
            setScore((prev) => prev + 1);
        } else {
            setCount((prev) => prev + 1);
        }
        if (count === 20) {
            console.log(count);
            setIsScore(false);
            setCount(1);
        }
        
    }
    var a = randoSequence(3);
    console.log(a);
    return (
        <div className="test">
            <div className="test-answer">
                {words
                    .filter((item) => item.idMain === parseInt(selectCount))
                    .filter((item) => item.id === count)
                    .map((item) => (
                        <div key={item.id} className="answer-wrapper">
                            {isScore ? (
                                <div>
                                    <div className="test-word">
                                        <h1>{item.english}</h1>
                                    </div>
                                    <button className="btn-answer" onClick={() => onClickNext(a[0])}>
                                        {item.inuzbek[a[0]]}
                                    </button>
                                    <button className="btn-answer" onClick={() => onClickNext(a[1])}>
                                        {item.inuzbek[a[1]]}
                                    </button>
                                    <button className="btn-answer" onClick={() => onClickNext(a[2])}>
                                        {item.inuzbek[a[2]]}
                                    </button>
                                    <button className="btn-answer" onClick={() => onClickNext(a[3])}>
                                        {item.inuzbek[a[3]]}
                                    </button>
                                </div>
                            ) : (
                                <div className="result">
                                    <h3>
                                        Sizning natijanginz <br /> 20 tadan {score} ta
                                    </h3>
                                    <button onClick={reset} className="btn-result">Qayta ishlash</button>
                                </div>
                            )}
                        </div>
                    ))}
            </div>
        </div>
    );
}

export default TestDictionary;
