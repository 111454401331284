export const DataDictionary = [
    {
        idMain: 1,
        id: 1,
        english: "I",
        uzbek: "Men",
        inuzbek: [
            "Men",
            "Sen",
            "Biz",
            "U(o'g'il bola)",
        ],
        transciption: "(ay)"

    },
    {
        idMain: 1,
        id: 2,
        english: "You",
        uzbek: "Sen",
        inuzbek: [
            "Sen",
            "Men",
            "Biz",
            "U(qiz bola)",
        ],
        transciption: "(yu)"
    },
    {
        idMain: 1,
        id: 3,
        english: "We",
        uzbek: "Biz",
        inuzbek: [
            "Biz",
            "Sen",
            "U(qiz bola)",
            "U(o'g'il bola)",
        ],
        transciption: "(ui)"
    },
    {
        idMain: 1,
        id: 4,
        english: "He",
        uzbek: "U(o'g'il bola)",
        inuzbek: [
            "U(o'g'il bola)",
            "Sen",
            "Biz",
            "U(qiz bola)",
        ],
        transciption: "(hi)"
    },
    {
        idMain: 1,
        id: 5,
        english: "She",
        uzbek: "U(qiz bola)",
        inuzbek: [
            "U(qiz bola)",
            "Sen",
            "Biz",
            "U(o'g'il bola)",
        ],
        transciption: "(shi)"
    },
    {
        idMain: 1,
        id: 6,
        english: "It",
        uzbek: "U(narsa)",
        inuzbek: [
            "U(narsa)",
            "Sen",
            "U(qiz bola)",
            "U(o'g'il bola)",
        ],
        transciption: "(it)"
    },
    {
        idMain: 1,
        id: 7,
        english: "They",
        uzbek: "Ular",
        inuzbek: [
            "Ular",
            "Sen",
            "Biz",
            "U(o'g'il bola)",
        ],
        transciption: "(dey)"
    },
    {
        idMain: 1,
        id: 8,
        english: "What",
        uzbek: "nima",
        inuzbek: [
            "nima",
            "kim",
            "qayerga",
            "nega",
        ],
        transciption: "(uot)"
    },
    {
        idMain: 1,
        id: 9,
        english: "Who",
        uzbek: "kim",
        inuzbek: [
            "kim",
            "qayerga",
            "qanday",
            "nega",
        ],
        transciption: "(hu)"
    },
    {
        idMain: 1,
        id: 10,
        english: "Where",
        uzbek: "qayerga",
        inuzbek: [
            "qayerga",
            "qanday",
            "nega",
            "qachon",
        ],
        transciption: "(ueya)"
    },
    {
        idMain: 1,
        id: 11,
        english: "How",
        uzbek: "qanday",
        inuzbek: [
            "qanday",
            "nega",
            "qachon",
            "qayerga",
        ],
        transciption: "(hau)"
    },
    {
        idMain: 1,
        id: 12,
        english: "Why",
        uzbek: "nega",
        inuzbek: [
            "nega",
            "qachon",
            "qayerga",
            "kim",
        ],
        transciption: "(uay)"
    },
    {
        idMain: 1,
        id: 13,
        english: "When",
        uzbek: "qachon",
        inuzbek: [
            "qachon",
            "qayerga",
            "kim",
            "nega",
        ],
        transciption: "(uen)"
    },
    {
        idMain: 1,
        id: 14,
        english: "My",
        uzbek: "mening",
        inuzbek: [
            "mening",
            "sening",
            "uning(o'g'il bola)",
            "uning(qiz bola)",
        ],
        transciption: "(may)"
    },
    {
        idMain: 1,
        id: 15,
        english: "Your",
        uzbek: "sening",
        inuzbek: [
            "sening",
            "mening",
            "bizning",
            "uning(narsa)",
        ],
        transciption: "(yor)"
    },
    {
        idMain: 1,
        id: 16,
        english: "His",
        uzbek: "uning(o'g'il bola)",
        inuzbek: [
            "uning(o'g'il bola)",
            "sening, sizning",
            "bizning",
            "uning(qiz bola)",
        ],
        transciption: "(his)"
    },
    {
        idMain: 1,
        id: 17,
        english: "Her",
        uzbek: "uning(qiz bola)",
        inuzbek: [
            "uning(qiz bola)",
            "sening",
            "bizning",
            "uning(o'g'il bola)",
        ],
        transciption: "(hyor)"
    },
    {
        idMain: 1,
        id: 18,
        english: "its",
        uzbek: "uning(narsa)",
        inuzbek: [
            "uning(narsa)",
            "sening",
            "uning(o'g'il bola)",
            "uning(qiz bola)",
        ],
        transciption: "(its)"
    },
    {
        idMain: 1,
        id: 19,
        english: "Our",
        uzbek: "bizning",
        inuzbek: [
            "bizning",
            "sening",
            "ularning",
            "uning(o'g'il bola)",
        ],
        transciption: "(aur)"
    },
    {
        idMain: 1,
        id: 20,
        english: "Their",
        uzbek: "ularning",
        inuzbek: [
            "ularning",
            "sening",
            "bizning",
            "uning(narsa)",
        ],
        transciption: "(deir)"
    },
]